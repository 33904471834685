var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "header",
        { staticClass: "pink section", staticStyle: { display: "flex" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "headerOptions",
              staticStyle: { "margin-left": "auto", "padding-top": "10px" }
            },
            [
              _c("span", { attrs: { title: "Start an interactive guide" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-dark",
                    staticStyle: { float: "right" },
                    on: {
                      click: function($event) {
                        return _vm.startTour()
                      }
                    }
                  },
                  [_vm._v("Help")]
                )
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { title: "Go to ProteoVision documentation" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      staticStyle: { float: "right" },
                      attrs: { id: "aboutButton" },
                      on: {
                        click: function($event) {
                          return _vm.goToAboutPage()
                        }
                      }
                    },
                    [_vm._v("About")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _c("span", { attrs: { title: "Reset the current session" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-dark",
                    staticStyle: { float: "right" },
                    attrs: { id: "resetButton" },
                    on: {
                      click: function($event) {
                        return _vm.resetRV3State()
                      }
                    }
                  },
                  [_vm._v("Reset")]
                )
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { title: "Save a ProteoVision session file" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      staticStyle: { float: "right" },
                      attrs: { id: "saveButton" },
                      on: {
                        click: function($event) {
                          return _vm.saveRV3State()
                        }
                      }
                    },
                    [_vm._v("Save session")]
                  )
                ]
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { padding: "2px", float: "right" } }),
              _vm._v(" "),
              _c(
                "span",
                { attrs: { title: "Load a ProteoVision session file" } },
                [
                  _c(
                    "label",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { for: "inputRV3State", id: "rv3-state-upload" }
                    },
                    [_vm._v("Load session")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    ref: "rv3_state_file",
                    attrs: {
                      id: "inputRV3State",
                      type: "file",
                      accept: ".json"
                    },
                    on: {
                      change: function($event) {
                        return _vm.loadRV3State()
                      }
                    }
                  })
                ]
              )
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c("v-tour", {
        attrs: {
          name: "myTour",
          steps: _vm.steps,
          callbacks: _vm.myCallbacks,
          options: { highlight: true }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "padding-top": "10px" } }, [
      _c("span", { staticClass: "title" }, [
        _vm._v("ProteoVision: Advanced Visualization of (Ribosomal) Proteins ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { title: "Go to DESIRE api" } }, [
      _c(
        "a",
        {
          staticClass: "btn btn-outline-dark",
          staticStyle: { float: "right" },
          attrs: {
            href: "/desire-api/",
            target: "_blank",
            id: "desireAPIButton"
          }
        },
        [_vm._v("API")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }