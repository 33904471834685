<template>
  <div>
    <RVGuide/>
    <DropDownTree/>
  </div>
</template>

<script>
  import RVGuide from './components/RVGuide.vue'
  import DropDownTree from './components/DropDownTree.vue'
  export default {
    components: {
      RVGuide,
      DropDownTree
    }
  }
</script>

