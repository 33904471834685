var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "phylo_tree_dropdown" } }, [
    _c("div", { staticClass: "left-sidebar" }, [
      _c(
        "div",
        {
          staticClass: "btn-group btn-group-toggle",
          attrs: { id: "tree_type", "data-toggle": "buttons" }
        },
        [
          _c(
            "label",
            {
              staticClass: "btn btn-outline-dark",
              staticStyle: { margin: "0 1% 0 0", width: "50%" },
              attrs: { for: "orthologs" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type_tree,
                    expression: "type_tree"
                  }
                ],
                attrs: {
                  type: "radio",
                  id: "orthologs",
                  value: "orth",
                  checked: ""
                },
                domProps: { checked: _vm._q(_vm.type_tree, "orth") },
                on: {
                  input: function($event) {
                    return _vm.cleanTreeOpts()
                  },
                  change: function($event) {
                    _vm.type_tree = "orth"
                  }
                }
              }),
              _vm._v("\n                DESIRE\n            ")
            ]
          ),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "btn btn-outline-dark",
              staticStyle: { margin: "0 0 0 1%", width: "50%" },
              attrs: { for: "upload" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.type_tree,
                    expression: "type_tree"
                  }
                ],
                attrs: { type: "radio", id: "upload", value: "upload" },
                domProps: { checked: _vm._q(_vm.type_tree, "upload") },
                on: {
                  input: function($event) {
                    return _vm.cleanTreeOpts()
                  },
                  change: function($event) {
                    _vm.type_tree = "upload"
                  }
                }
              }),
              _vm._v("\n                User upload\n            ")
            ]
          )
        ]
      ),
      _vm._v(" "),
      (_vm.type_tree == "para") | (_vm.type_tree == "orth")
        ? _c(
            "div",
            { attrs: { id: "treeselect" } },
            [
              _c(
                "treeselect",
                {
                  ref: "treeselect",
                  attrs: {
                    "load-options": _vm.loadOptions,
                    placeholder: "Select a phylogenetic group",
                    "no-children-text": "Loading... or no children",
                    multiple: true,
                    options: _vm.options,
                    flat: true,
                    limit: 3,
                    "default-expand-level": 1
                  },
                  on: {
                    input: function($event) {
                      return _vm.loadData(_vm.tax_id, _vm.type_tree)
                    }
                  },
                  model: {
                    value: _vm.tax_id,
                    callback: function($$v) {
                      _vm.tax_id = $$v
                    },
                    expression: "tax_id"
                  }
                },
                [
                  _vm._v("Loading phylogenetic tree "),
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: {
                      src: "static/img/loading.gif",
                      alt: "Loading phylogenetic tree"
                    }
                  })
                ]
              )
            ],
            1
          )
        : _c("div", [
            _vm._v("\n            Select an alignment file:\n            "),
            _c("p", [
              _c("input", {
                ref: "custom_aln_file",
                staticClass: "btn btn-outline-dark",
                attrs: {
                  id: "inputUploadFasta",
                  type: "file",
                  accept: ".fasta,.fas,.fa"
                },
                on: {
                  change: function($event) {
                    return _vm.handleFileUpload()
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("p", [
              _vm.file && _vm.type_tree == "upload"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { id: "uploadShowFasta" },
                      on: {
                        click: function($event) {
                          return _vm.submitCustomAlignment()
                        }
                      }
                    },
                    [_vm._v("Upload the alignment")]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("p", [
              !_vm.file && _vm.type_tree == "upload"
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-dark",
                      attrs: { id: "downloadExampleFasta" },
                      on: {
                        click: function($event) {
                          return _vm.getExampleFile(
                            "static/alignments/EFTU_example.fas",
                            "PVExampleAlignment.fas"
                          )
                        }
                      }
                    },
                    [_vm._v("Download example alignment")]
                  )
                : _vm._e()
            ])
          ]),
      _vm._v(" "),
      _c("p", [
        _vm.tax_id
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.alnobj,
                    expression: "alnobj"
                  }
                ],
                staticClass: "btn btn-outline-dark dropdown-toggle",
                attrs: { id: "selectaln" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.alnobj = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _vm.tax_id
                  ? _c(
                      "option",
                      {
                        attrs: { selected: "", disabled: "", hidden: "" },
                        domProps: { value: null }
                      },
                      [_vm._v("Select an alignment")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.alignments, function(aln) {
                  return _vm.tax_id
                    ? _c(
                        "option",
                        {
                          domProps: { value: { id: aln.value, text: aln.text } }
                        },
                        [_vm._v(_vm._s(aln.text))]
                      )
                    : _vm._e()
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.file &&
      _vm.type_tree == "upload"
        ? _c("div", [
            _c(
              "label",
              {
                staticClass: "btn btn-outline-dark",
                attrs: { for: "uploadCustomPDB", id: "pdb-upload" }
              },
              [_vm._v("Upload a custom PDB")]
            ),
            _vm._v(" "),
            _c("input", {
              ref: "customPDBfile",
              staticClass: "btn btn-outline-dark",
              attrs: { id: "uploadCustomPDB", type: "file", accept: ".pdb" },
              on: {
                change: function($event) {
                  return _vm.uploadCustomPDB()
                }
              }
            }),
            _vm._v("\n                OR"),
            _c("br")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.alnobj ? _c("span", [_vm._v("Select/type PDB entry:")]) : _vm._e(),
      _vm._v(" "),
      _c(
        "p",
        [
          _vm.alnobj && _vm.alnobj != "custom"
            ? _c("autocomplete", {
                attrs: { id: "pdb_input", "isAsync:true": "", items: _vm.pdbs },
                model: {
                  value: _vm.pdbid,
                  callback: function($$v) {
                    _vm.pdbid = $$v
                  },
                  expression: "pdbid"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.alnobj && _vm.alnobj == "custom"
            ? _c("autocomplete", {
                attrs: { "isAsync:true": "", items: _vm.blastPDBresult },
                model: {
                  value: _vm.pdbid,
                  callback: function($$v) {
                    _vm.pdbid = $$v
                  },
                  expression: "pdbid"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.fetchingPDBwithCustomAln &&
      _vm.fetchingPDBwithCustomAln == true
        ? _c("div", { attrs: { id: "blastingPDBsMSG" } }, [
            _c("b", [
              _vm._v("BLASTing first alignment sequence against PDB sequences")
            ]),
            _vm._v(" "),
            _c("img", {
              staticStyle: { height: "25px" },
              attrs: {
                src: "static/img/loading.gif",
                alt: "BLASTing available PDBs"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.fetchingPDBwithCustomAln &&
      _vm.fetchingPDBwithCustomAln == "none"
        ? _c("div", { attrs: { id: "blastedPDBsNoneMSG" } }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.fetchingPDBwithCustomAln &&
      _vm.fetchingPDBwithCustomAln == "error"
        ? _c("div", { attrs: { id: "blastedPDBsNoneMSG" } }, [
            _c("b", [
              _vm._v(
                "BLAST error! Please try different alignment or refreshing the page!"
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.alnobj &&
      _vm.alnobj == "custom" &&
      _vm.fetchingPDBwithCustomAln == "complete"
        ? _c("span", { attrs: { id: "completeBLASTsMSG" } }, [
            _c("b", [_vm._v("Completed BLAST for similar PDBs.")])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hide_chains
        ? _c("div", { attrs: { id: "onFailedChains" } }, [
            _vm._v("Looking for available polymers "),
            _c("img", {
              staticStyle: { height: "25px" },
              attrs: {
                src: "static/img/loading.gif",
                alt: "Searching available polymers"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm._v(" "),
      _c("p", [
        (_vm.chains && _vm.fasta_data && _vm.pdbid) || _vm.uploadSession
          ? _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.chainid,
                    expression: "chainid"
                  }
                ],
                staticClass: "form-control btn-outline-dark",
                style: { resize: "both" },
                attrs: { multiple: "", id: "polymerSelect" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.chainid = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _c(
                  "option",
                  {
                    attrs: { selected: "", disabled: "" },
                    domProps: { value: null }
                  },
                  [_vm._v("Select a polymer")]
                ),
                _vm._v(" "),
                _vm._l(_vm.chains, function(chain) {
                  return _c(
                    "option",
                    {
                      domProps: { value: chain.value },
                      on: {
                        click: function($event) {
                          _vm.postStructureData(_vm.pdbid, _vm.chainid)
                          _vm.populateECODranges(_vm.pdbid, _vm.chainid)
                          _vm.showPDBViewer(
                            _vm.pdbid,
                            _vm.chainid,
                            chain.entityID
                          )
                        }
                      }
                    },
                    [_vm._v(_vm._s(chain.text))]
                  )
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.structure_mapping
        ? _c("div", [
            _vm.topology_loaded
              ? _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.downloadMapDataOpt,
                        expression: "downloadMapDataOpt"
                      }
                    ],
                    staticClass: "btn btn-outline-dark dropdown-toggle",
                    attrs: { id: "downloadDataBtn" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.downloadMapDataOpt = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      {
                        attrs: { selected: "", disabled: "" },
                        domProps: { value: null }
                      },
                      [_vm._v("Download mapped data")]
                    ),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "csv" } }, [
                      _vm._v("As CSV file")
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "pymol" } }, [
                      _vm._v("As PyMOL script")
                    ])
                  ]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm.topology_loaded && _vm.type_tree == "orth"
        ? _c(
            "div",
            { staticClass: "checkbox", attrs: { id: "showRNAcontext" } },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checkedRNA,
                      expression: "checkedRNA"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.checkedRNA)
                      ? _vm._i(_vm.checkedRNA, null) > -1
                      : _vm.checkedRNA
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.checkedRNA,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkedRNA = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkedRNA = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkedRNA = $$c
                        }
                      },
                      function($event) {
                        return _vm.updateMolStarWithRibosome(_vm.checkedRNA)
                      }
                    ]
                  }
                }),
                _vm._v("\n                Show ribosomal context in 3D")
              ]),
              _vm._v(" "),
              _c("p")
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.topology_loaded && !_vm.checkedRNA && !_vm.customPDBid
        ? _c("div", [
            _c(
              "div",
              {
                staticStyle: { margin: "3% 0" },
                attrs: { id: "domainSelectionSection" }
              },
              [
                _c("div", [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.domain_or_selection,
                          expression: "domain_or_selection"
                        }
                      ],
                      attrs: { type: "radio", value: "domain" },
                      domProps: {
                        checked: _vm._q(_vm.domain_or_selection, "domain")
                      },
                      on: {
                        change: function($event) {
                          _vm.domain_or_selection = "domain"
                        }
                      }
                    }),
                    _vm._v("\n                    Select by ECOD domain")
                  ])
                ]),
                _vm._v(" "),
                _vm.selected_domain.length > 0
                  ? _c("div", { staticStyle: { "text-align": "center" } }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "http://prodata.swmed.edu/ecod/complete/domain/" +
                              _vm.selected_domain[0].id,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "See " +
                              _vm._s(_vm.selected_domain[0].id) +
                              " on ECOD"
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.domain_list && _vm.checked_domain
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected_domain,
                            expression: "selected_domain"
                          }
                        ],
                        staticClass: "form-control btn-outline-dark",
                        staticStyle: { margin: "1.5% 0" },
                        style: { resize: "both" },
                        attrs: { multiple: "", id: "domainSelect" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected_domain = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.domain_list, function(domain) {
                        return _c("option", { domProps: { value: domain } }, [
                          _vm._v(_vm._s(domain.name))
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.selected_domain.length > 0
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-dark",
                        staticStyle: { margin: "1.5% 0" },
                        attrs: {
                          id: "disableDomainTruncation",
                          type: "button"
                        },
                        on: {
                          click: function($event) {
                            _vm.domain_or_selection = null
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                        Show the entire structure\n                "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c("div", { attrs: { id: "filterSection" } }, [
              _c("p"),
              _c("div", [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.domain_or_selection,
                        expression: "domain_or_selection"
                      }
                    ],
                    attrs: { type: "radio", value: "selection" },
                    domProps: {
                      checked: _vm._q(_vm.domain_or_selection, "selection")
                    },
                    on: {
                      change: function($event) {
                        _vm.domain_or_selection = "selection"
                      }
                    }
                  }),
                  _vm._v("\n                    Select custom range")
                ])
              ]),
              _vm._v(" "),
              _vm.checked_selection
                ? _c("span", [
                    _c("b", [_vm._v("Input single")]),
                    _vm._v(" residue range to "),
                    _c("b", [_vm._v("show")]),
                    _vm._v(", ending with semicolon. "),
                    _c("br"),
                    _vm._v(" For example: 1-80;")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.checked_selection
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filter_range,
                        expression: "filter_range"
                      }
                    ],
                    staticClass: "input-group-text",
                    domProps: { value: _vm.filter_range },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.filter_range = $event.target.value
                        },
                        function($event) {
                          return _vm.handleFilterRange(_vm.filter_range)
                        }
                      ]
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm.filter_range
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-dark",
                        staticStyle: { margin: "3% 0" },
                        attrs: { id: "disableCutTruncation", type: "button" },
                        on: {
                          click: function($event) {
                            _vm.domain_or_selection = null
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Show the entire structure\n                "
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("p")
            ]),
            _vm._v(" "),
            _c("div", { attrs: { id: "maskingSection" } }, [
              _c("p"),
              _c("div", { staticClass: "checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked_filter,
                        expression: "checked_filter"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.checked_filter)
                        ? _vm._i(_vm.checked_filter, null) > -1
                        : _vm.checked_filter
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.checked_filter,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checked_filter = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked_filter = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked_filter = $$c
                          }
                        },
                        function($event) {
                          return _vm.cleanFilter(
                            _vm.checked_filter,
                            _vm.masking_range
                          )
                        }
                      ]
                    }
                  }),
                  _vm._v("\n                    Mask/Unmask 2D and 3D residues")
                ])
              ]),
              _vm._v(" "),
              _vm.checked_filter
                ? _c("span", [
                    _c("b", [_vm._v("Input multiple")]),
                    _vm._v(" residue ranges to "),
                    _c("b", [_vm._v("show")]),
                    _vm._v(", separated by semicolon. "),
                    _c("br"),
                    _vm._v(" For example: 1-80;91-111;")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.checked_filter
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.masking_range,
                        expression: "masking_range"
                      }
                    ],
                    staticClass: "input-group-text",
                    domProps: { value: _vm.masking_range },
                    on: {
                      input: [
                        function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.masking_range = $event.target.value
                        },
                        function($event) {
                          return _vm.handleMaskingRanges(_vm.masking_range)
                        }
                      ]
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p")
            ]),
            _vm._v(" "),
            _vm.correct_mask != true && _vm.masking_range != null
              ? _c("p", [_vm._v("Incorrect range syntax!")])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { id: "customDataSection" } }, [
              _c("p"),
              _c("div", { staticClass: "checkbox" }, [
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checked_customMap,
                        expression: "checked_customMap"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.checked_customMap)
                        ? _vm._i(_vm.checked_customMap, null) > -1
                        : _vm.checked_customMap
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.checked_customMap,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.checked_customMap = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checked_customMap = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checked_customMap = $$c
                          }
                        },
                        function($event) {
                          return _vm.cleanCustomMap(_vm.checked_customMap)
                        }
                      ]
                    }
                  }),
                  _vm._v("\n                    Upload custom mapping data")
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm.checked_customMap
                    ? _c("input", {
                        ref: "custom_csv_file",
                        staticClass: "btn btn-outline-dark",
                        attrs: {
                          id: "inputUploadCSV",
                          type: "file",
                          accept: ".csv"
                        },
                        on: {
                          change: function($event) {
                            return _vm.handleCustomMappingData()
                          }
                        }
                      })
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.raiseCustomCSVWarn
                  ? _c("p", {
                      domProps: { innerHTML: _vm._s(_vm.raiseCustomCSVWarn) }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("p", [
                  _vm.checked_customMap && _vm.csv_data == null
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-dark",
                          attrs: { id: "downloadExampleCSV", type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.getExampleFile(
                                "static/alignments/rv3_example_cusom_mapping.csv",
                                "PVExampleCustomMapping.csv"
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Download example mapping data\n                    "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("p")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p"),
      _vm.alnobj
        ? _c(
            "div",
            { staticClass: "checkbox", attrs: { id: "showFrequencies" } },
            [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.checked_propensities,
                      expression: "checked_propensities"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.checked_propensities)
                      ? _vm._i(_vm.checked_propensities, null) > -1
                      : _vm.checked_propensities
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.checked_propensities,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.checked_propensities = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checked_propensities = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checked_propensities = $$c
                        }
                      },
                      function($event) {
                        return _vm.handlePropensities(_vm.checked_propensities)
                      }
                    ]
                  }
                }),
                _vm._v("\n            Show amino acid frequencies")
              ]),
              _vm._v(" "),
              _vm.checked_propensities && _vm.structure_mapping
                ? _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.property,
                          expression: "property"
                        }
                      ],
                      staticClass: "btn btn-outline-dark dropdown-toggle",
                      attrs: { id: "propensitiesSubstructure" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.property = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function($event) {
                            _vm.getPropensities(_vm.property)
                            _vm.handlePropensities(_vm.checked_propensities)
                          }
                        ]
                      }
                    },
                    [
                      _c(
                        "option",
                        {
                          attrs: { selected: "", disabled: "", hidden: "" },
                          domProps: { value: null }
                        },
                        [_vm._v("Select secondary structure")]
                      ),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 0 } }, [
                        _vm._v("All residues")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.substructures, function(substructure) {
                        return _c(
                          "option",
                          {
                            domProps: {
                              value: {
                                id: substructure.value,
                                text: substructure.text,
                                indices: substructure.indices
                              }
                            }
                          },
                          [_vm._v(_vm._s(substructure.text))]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("p", [
                _vm.checked_propensities
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-dark",
                        staticStyle: { margin: "3% 0" },
                        attrs: { id: "downloadFreqsBtn", type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.downloadFreqsData()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                Download AA frequencies\n            "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]
          )
        : _vm._e(),
      _c("p")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "alignment_section" }, [
      _vm.alnobj
        ? _c("div", { attrs: { id: "alnif" } }, [
            _c(
              "div",
              { staticStyle: { display: "flex" }, attrs: { id: "alnMenu" } },
              [
                _vm.msavWillMount
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-outline-dark",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "downloadFastaBtn", type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.downloadAlignmentData()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Download alignment\n                "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.cdHITReport
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cdhitSelectedOpt,
                            expression: "cdhitSelectedOpt"
                          }
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "cdHITResults" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.cdhitSelectedOpt = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("See cdhit options")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.cdhitOpts, function(prop) {
                          return _c(
                            "option",
                            { domProps: { value: prop.value } },
                            [_vm._v(_vm._s(prop.Name))]
                          )
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.downloadAlignmentOpt,
                            expression: "downloadAlignmentOpt"
                          }
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "downloadAlnImageBtn" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.downloadAlignmentOpt = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("Download alignment image")]
                        ),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "full" } }, [
                          _vm._v("Full alignment")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "visible" } }, [
                          _vm._v("Visible alignment")
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected_property,
                            expression: "selected_property"
                          }
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "selectColorMappingProps" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected_property = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("Select data")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.available_properties, function(prop) {
                          return _c("option", [_vm._v(_vm._s(prop.Name))])
                        })
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.msavWillMount
                  ? _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.colorScheme,
                            expression: "colorScheme"
                          }
                        ],
                        staticClass: "btn btn-outline-dark dropdown-toggle",
                        staticStyle: { margin: "0 1%" },
                        attrs: { id: "selectAlnColorScheme" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.colorScheme = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c(
                          "option",
                          {
                            attrs: { selected: "", disabled: "" },
                            domProps: { value: null }
                          },
                          [_vm._v("Select a colorscheme")]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.availColorschemes, function(colorscheme) {
                          return _c("option", [_vm._v(_vm._s(colorscheme))])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _vm._m(1)
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "warningSection" }, [
      _vm.cdHITReport && _vm.didCDHit_truncate
        ? _c("div", { attrs: { id: "warningCDHITtruncation" } }, [
            _c("b", [
              _vm._v(
                "Warning, your alignment sequences were clustered by cdhit! See dropdown menu above the alignment for options."
              ),
              _c("br"),
              _vm._v(
                "\n            Original alignment had " +
                  _vm._s(this.cdHITnums[0]) +
                  " sequences, which were clustered in " +
                  _vm._s(this.cdHITnums[1]) +
                  " groups using threshold of 90% identity."
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.poor_structure_map
        ? _c("div", { attrs: { id: "warningPoorStructureAln" } }, [
            _c("b", [
              _vm._v(
                "Warning, poor alignment between the structure and sequences!!!"
              ),
              _c("br"),
              _vm._v(
                "\n            Found " +
                  _vm._s(_vm.poor_structure_map) +
                  " poorly aligned residues.\n            Proceed with caution or try a different structure."
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "topology_section" }, [
      _vm.chainid.length > 0 || _vm.customPDBsuccess
        ? _c("span", { attrs: { id: "topif" } }, [
            !_vm.topology_loaded
              ? _c("div", [
                  _vm._v(
                    "\n                Loading alignment-structure mapping "
                  ),
                  _c("img", {
                    staticStyle: { height: "25px" },
                    attrs: {
                      src: "static/img/loading.gif",
                      alt: "Loading topology viewer"
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { attrs: { id: "topview" } })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.topology_loaded
      ? _c(
          "div",
          { staticClass: "gradient_section" },
          _vm._l(_vm.available_properties, function(prop) {
            return _vm.selected_property == prop.Name
              ? _c("img", { attrs: { id: "gradientSVG", src: prop.url } })
              : _vm._e()
          }),
          0
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "molstar_section" }, [
      _vm.PDBparsing == true
        ? _c("div", [
            _vm._v("\n            Parsing PDB structure "),
            _c("img", {
              staticStyle: { height: "25px" },
              attrs: {
                src: "static/img/loading.gif",
                alt: "Parsing PDB structure"
              }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.PDBparsing == "error"
        ? _c("div", [
            _vm._v(
              "\n            Failed to parse the PDB structure! Try a different structure.\n        "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.chainid.length > 0 || _vm.customPDBsuccess
        ? _c("span", { attrs: { id: "molif" } }, [_vm._m(2)])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "propensity_section" }, [
      _vm.checked_propensities
        ? _c("span", { attrs: { id: "propif" } }, [
            _c("div", { attrs: { id: "total" } })
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm._m(3)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _vm._v("BLAST didn't match any PDBs!"),
      _c("br"),
      _vm._v("You can still type in any PDB.")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "alnDiv" } }, [
      _vm._v("Loading alignment "),
      _c("img", {
        staticStyle: { height: "25px" },
        attrs: { src: "static/img/loading.gif", alt: "Loading alignment" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pdbeMolstarView" } }, [
      _vm._v("\n                Loading Molstar Component "),
      _c("img", {
        staticStyle: { height: "25px" },
        attrs: { src: "static/img/loading.gif", alt: "Loading MolStar" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", [
      _c("div", {
        staticStyle: { display: "flex" },
        attrs: { id: "footerDiv" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }